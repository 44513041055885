










import { WPService } from "../services/WPService";
import Vue from "vue";

const WorksSingle = Vue.extend({
  data: function () {
    return {
      post: {},
    };
  },
  mounted: function () {
    WPService.loadPostBySlug(this.$route.params.slug).then(
      (data) => (this.post = data[0]),
      (error) => console.log(error)
    );
  },
});
export default WorksSingle;
